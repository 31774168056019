import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from "@material-ui/core/Typography";
import $ from "jquery";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const dollarsPrize = [1, 0.75, 0.75, 0.75, 0.5, 0.5, 0.5, 0.4, 0.3, 0.3, 0.2, 0.2, 0.2, 0.15, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,];

function renderRow(props) {
    const { data, index, style } = props;

    // const nameUser = data[index]['first_name'] + ' ' + (data[index]['last_name'] ? data[index]['last_name'][0] + '.' : '');
    const nameUser = data[index]['first_name'];
    return (
        <ListItem button style={style} key={index}>

            <ListItemText primary={<Typography style={{ fontSize: '9pt' }}>{index + 1}</Typography>} />
            <Grid container item xs={12} spacing={4}>
            <ListItemAvatar style={{ marginLeft: '25px' }}>
                <Avatar style={{ height: '35px', width: '35px' }} alt="" src={data[index]['photo_url']} />
            </ListItemAvatar>
                <ListItemText primary={<Typography style={{ fontSize: '10pt' }}>{nameUser}</Typography>} />
            </Grid>

            <Box>
                <ListItemText primary={<Typography style={{ fontSize: '10pt', fontWeight: 500 }}>{data[index]['carrots']}</Typography>} />
            </Box>
            <Avatar style={{ height: '28px', width: '28px' }} alt="" src="https://test60.ru/bunny_fps/menu/imgs/carrot2.png" />

            {
                index < 20
                ? <Box>
                    <ListItemText primary={<Typography style={{ marginLeft: '0.4rem', fontSize: '10pt', fontWeight: 500 }}>{Number(dollarsPrize[index]).toFixed(2).toString()}</Typography>} />
                </Box>
                : null
            }
            {
                index >= 20
                    ? <Box>
                        <ListItemText primary={<Typography style={{ marginLeft: '0.4rem', fontSize: '10pt', fontWeight: 500 }}>{'0.10'}</Typography>} />
                    </Box>
                    : null
            }
            {
                index <= 200
                ? <Avatar style={{ height: '28px', width: '28px' }} alt="" src="https://test60.ru/bunny_fps/menu/imgs/dollar.png" />
                : null
            }



        </ListItem>
    );
};

function formatName(nameUser) {

    const widthWindow = document.documentElement.clientWidth;
    let maxSizeText = 11; //длина сокращения текста
    let newName = nameUser.toString();

    if (widthWindow > 340) maxSizeText = 19;

    if (newName.length >= maxSizeText) {
        newName = newName.slice(0, maxSizeText) + '..';
    }

    return newName;
}

renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
}

export default function VirtualizedList(props) {

    //console.log('chat_id = ', props.chat_id);

    const classes = useStyles();
    const heightWindow = Number(props.height);

    const [dataTop, setDataTop] = useState([]);

    const getTop100 = () => {
        console.log('Турнир ТОП 100');
        $.ajax({
            url: !props.history ? 'https://test60.ru/bunny_fps/game/php/getTurnirTop100.php' : 'https://test60.ru/bunny_fps/game/php/getWinnersTop100.php',
            type: 'post',
            data: {
                //score: score,
            },
            success: function (response) {
                const dataJson = JSON.parse(response); //Записываем данные топа
                setDataTop(dataJson);

                // Проверяем, если мы есть в топе, то визуально начисляем награду $ за место
                for (let i = 0; i < dataJson.length; i++) {
                    if (Number(dataJson[i].chat_id) === Number(props.chat_id)) {
                        //console.log('Наш ИД в ТОПЕ ', i);
                    }
                }

            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            }
        });
    };

    useMemo(
        () => getTop100(),
        []
    );

    return (
        <div className={classes.root}>
            <FixedSizeList height={heightWindow} width={'100%'} itemSize={50} itemCount={dataTop.length} itemData={dataTop}>
                {renderRow}
            </FixedSizeList>
        </div>
    );
}
