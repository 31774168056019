import React, {useEffect, useMemo, useState} from 'react';

import {randomInteger, saveMyRecord, getFlashVars} from './Utils';
import Iframe from './Iframe';
import TopList from './TopList';
import TurnirTopList from './TurnirTopList';
import StartTurnir from './StartTurnir';
import StopTurnir from "./StopTurnir";
import NoEnergy from "./NoEnergy";
import ResultTurnir from "./ResultTurnir";
import Preloader from "./Preloader";
import Stavka from "./Stavka";
import ModeGame from "./ModeGame";
import Bank from "./Bank";
import Invite from "./Invite";
import StopBot from "./StopBot";
import GoTelegram from "./GoTelegram";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {Divider} from "@material-ui/core";
import $ from "jquery";
import TelegramIcon from '@material-ui/icons/Telegram';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(0),
    color: '#111111',
    fontSize: '13pt',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  desc: {
    marginLeft: theme.spacing(0),
    color: '#111111',
    fontSize: '12pt',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  icon_money: {
    marginLeft: 'auto',
  },
  menu: {
    backgroundColor: '#ffffff',
  },
  resoursePanel: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#fdfdfd',
    border: '1px solid #B7B7B7',
    borderRadius: 30,
  },
  topPanel: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  topIcon: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0.1),
    marginBottom: theme.spacing(0.1),
  },
  infoPanel: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
  },
  btn_play: {
    background: 'linear-gradient(90deg, rgba(71,174,0,1) 0%, rgba(0,203,16,1) 57%, rgba(0,233,11,1) 100%)',
    borderRadius: 5,
    border: 0,
    color: 'white',
    width: '270px',
    height: '50px',
    fontSize: '14pt',
    boxShadow: '0 3px 5px 2px rgba(230, 255, 232, 1)',
  },
  btn_auth: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    borderRadius: 5,
    border: 0,
    color: 'white',
    width: '290px',
    height: '50px',
    fontSize: '11pt',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
  iconCup: {
    position: 'absolute',
    top: '23px',
    left: '8px',
  },
}));



let costylCarrots = false;
let stavkaValue = 1; // СТАВКА от 1 до 5
let seconsFinishTurnir = 0; // ВРЕМЯ ДО КОНЦА ТУРНИРА
let canADS = true; // разрешено ли загружать рекламу
let loadedADS = false; // загружена реклама или нет

const MenuGame = () => {

  const classes = useStyles();
  const srcIframe = 'https://test60.ru/bunny_fps/game/';

  const maxWidthWindow = 600; //максимальная ширина iFrame
  const heightWindow = String(document.documentElement.clientHeight);
  let widthWindow = String(document.documentElement.clientWidth);
  if (widthWindow > maxWidthWindow) widthWindow = maxWidthWindow;


  const [openIframe, setOpenIframe] = useState(false); // Открыт-ли IFRAME с игрой
  const [authTG, setAuthTG] = useState(false); // ПРОВЕРКА АВТОРИЗАЦИИ


  const flashVars = getFlashVars();

  /*
  if (flashVars['hash'] === undefined || flashVars['hash'] === '') {
    flashVars['hash'] = '2e5b597761cb12';
    flashVars['id'] = 1477714585;
  }
  */

  let hashUser = flashVars['hash']; //хэш авторизации Телеграм
  let chatIDUser = flashVars['id']; //айди юзера Телеграм


  const [myRecord, setMyRecord] = useState(0); // рекорд в обычном режиме - максимальный
  const [myScore, setMyScore] = useState(0); // счет в обычном режиме

  const [mySopernikScore, setSopernikScore] = useState(0); // счет соперника
  const [myStavka, setMyStavka] = useState(stavkaValue); // СТАВКА - для вывода в окне


  const [energy, setEnergy] = useState(0); // ЭНЕРГИЯ
  const [videoBonusCount, setVideoBonusCount] = useState(0); // КОЛ-ВО ПРОСМОТРОВ ВИДЕОРЕКЛАМЫ

  const [myCarrots, setMyCarrots] = useState(0); // МОРКОВКИ
  const [myDollars, setMyDollars] = useState(0); // ДОЛЛАРЫ

  const [codeComplete, setCodeComplete] = useState(0); // ПОЛУЧЕНА-ЛИ ЭНЕРГИЯ ПО КОДУ

  const [countOnline, setCountOnline] = useState(0); // Кол-во игроков онлайн

  const [showPreloader, setShowPreloader] = useState(false); // ПРЕЛОАДЕР

  const [showBank, setShowBank] = useState(false); // БАНК - ВЫВОД
  const [showInvite, setShowInvite] = useState(false); // ИНВАЙТ - ПРИГЛАШЕНИЕ



  const [showGame, setShowGame] = useState(false); // ИГРА ЗАПУЩЕНА - открыта
  const [startGame, setStartGame] = useState(false); // ИГРА ЗАГРУЖЕНА и НАЧАЛАСЬ

  const [searchSopernik, setSearchSopernik] = React.useState(false); // Окно: ПОИСК СОПЕРНИКА
  const [stopTurnir, setStopTurnir] = React.useState(false); // Окно: ТУРНИР ОСТАНОВЛЕН (пауза)
  const [noEnergy, setNoEnergy] = React.useState(false); // Окно: НЕТ ЭНЕРГИИ
  const [resultTurnir, setResultTurnir] = React.useState(false); // Окно: РЕЗУЛЬТАТ ТУРНИРА (победа/проигрыш)
  const [victoryTurnir, setVictoryTurnir] = React.useState(false); // РЕЗУЛЬТАТ ТУРНИРА
  const [stavkaTurnir, setStavkaTurnir] = React.useState(false); // Окно: СТАВКА НА ТУРНИР
  const [modeGame, setModeGame] = React.useState(false); // Окно: ВЫБОР РЕЖИМА ИГРА-ТУРНИР

  const [stopBot, setStopBot] = React.useState(false); // Окно: ДОСТУП К БОТУ ЗАКРЫТ

  const [goTelegram, setGoTelegram] = React.useState(false); // Окно: ПЕРЕХОД В ТЕЛЕГРАМ

  const [addBonus, setAddBonus] = React.useState(false); // Окно: БОНУС ЗА ПРОСМОТР РЕКЛАМЫ

  const [avatarPlayer, setAvatarPlayer] = React.useState(''); // Аватар игрока
  const [avatarSpoernik, setAvatarSpoernik] = React.useState(''); // Аватар соперника

  const [TurnirMode, setTurnirMode] = React.useState(false); // РЕЖИМ ТУРНИРА - true - включен
  const [historyTurnir, setHistoryTurnir] = React.useState(false); // ИСТОРИЯ ТУРНИРА - true - включен
  const [tabsValue, setTabsValue] = React.useState(0); // номер переключателя топа

  const [timeTurnir, setTimeTurnir] = React.useState(0); // ДЛИТЕЛЬНОСТЬ ТУРНИРА С СОПЕРНИКОМ (секунды)
  const [timeFinishTurnir, setTimeFinishTurnir] = React.useState('24 ч. 0 м.'); // ВРЕМЯ ДО КОНЦА ТУРНИРА

  // Ловим МОМЕНТ ПЕРВОГО ЗАПУСКА
  React.useMemo(
      () => onStartApp(), // загружаем все данные
      []
  );

  // Ловим МОМЕНТ ИГРЫ
  React.useEffect(() => {

    // ТАЙМЕР ИГРЫ - обратный отсчет
    if (TurnirMode && startGame) {
      if (timeTurnir > 0) {
        setTimeout(() => setTimeTurnir(timeTurnir - 1), 1000); // отнимаем по 1 сек
      } else {

        // ИГРА ЗАВЕРШЕНА - таймер остановлен
        setTimeTurnir(0);
        goMenuTurnir(); // возвращаемся в меню
      }
    }

  });

  // ПЕРВЫЙ ЗАПУСК
  function onStartApp() {

    // если авторизован
    if (hashUser !== undefined && hashUser !== '' && chatIDUser !== undefined && chatIDUser !== '') {
      setAuthTG(true);
      setModeGame(true); // ВЫБОР РЕЖИМА
      getCountOnline(); // Подгружаем кол-во онлайн
    } else {
      setGoTelegram(true);
    }

  }


  // НАЧАТЬ ТУРНИР
  const onStartTurnir = () => {
    setStavkaTurnir(true); // Показываем окно ставок
  }

  // ПОИСК СОПЕРНИКА - РЕКЛАМА ПЕРЕД
  const onSearchSopernik = (stavka) => {

    console.log('СТАВКА = ', stavka);
    stavkaValue = stavka; // задаем ставку турнира
    setMyStavka(stavkaValue);

    onAddSearchSopernik(); // ПОИСК СОПЕРНИКА

  }

  // ПОИСК СОПЕРНИКА - ЗАПУСК
  const onAddSearchSopernik = () => {

    hideStavkaTurnir(false);
    setShowPreloader(true);

    setMyScore(0); // Обнуляем счет
    costylCarrots = false; //костыль
    getAvaSopernik(hashUser); // получаем аватарку соперника онлайн

  }


  // СТАРТ ИГРЫ: Обычный режим
  const onGamePlay = () => {
    setShowGame(true);
    if(!openIframe) setOpenIframe(true);
  };

  // СТАРТ ИГРЫ: Турнир
  const onGamePlayTurnir = () => {
    onGamePlay();
    setSearchSopernik(false);
    setTimeTurnir(randomInteger(10, 20)); // ТАЙМЕР ВРЕМЯ ТУРНИРА
  };


  // БАНК - ВЫВОД
  const goBank = () => {
    setShowBank(true);
  }

  // ИНВАЙТ - ПРИГЛАШЕНИЕ
  const goInvite = () => {
    setShowInvite(true);
  }


  // Переход в меню ИГРЫ
  const goMenuGame = () => {
    setShowGame(false);
    setStartGame(false);
  }

  // Переход в меню ТУРНИРА
  const goMenuTurnir = () => {

    onUpdateTimeFinish(); // обновляем время до конца турнира

    setShowGame(false);
    setStartGame(false);

    //прибавляем 1 морковку - для теста
    if (!costylCarrots) {
      costylCarrots = true;

      setShowPreloader(true); //прелоадер
      setTurnir(hashUser); // записываем результат турнира в БД
    }

  }


  // Переход по ссылке в БОТ
  const onAuthTG = () => {
    //window.location.href = "tg://resolve?domain=CheckerFPSBot";
    window.location.href = "tg://resolve?domain=BunnyFPSBot&start=site_test60"; // с записью новых по реферальной
  }

  window.addEventListener("message", onMessageReceived, false);
  function onMessageReceived(event) {
    const data = JSON.parse(event.data);
    if(data.message.action === "update_score") {
      const newScore = Number(data.message.value);
      setMyScore(newScore);
    }
  }


  // Авторизация и синхронизация с БД
  function onAutorization() {

      onGamePlay();

      // если нет данных об авторизации, то не грузим ничего
      //if (hashUser === undefined || hashUser === '' || chatIDUser === undefined || chatIDUser === '') return;

  }


  // Получение данных юзера
  function onGetUserData() {
      $.ajax({
          url: 'https://test60.ru/bunny_fps/game/php/getUserData.php',
          type: 'post',
          data: {
              hash: flashVars['hash']
          },
          success: function (response) {

              const dataJson = JSON.parse(response);

              setMyRecord(Number(dataJson[0]['score']));
              setAvatarPlayer(dataJson[0]['photo_url']);

              setEnergy(Number(dataJson[0]['energy']));
              setVideoBonusCount(Number(dataJson[0]['video_bonus']));

              setMyCarrots(Number(dataJson[0]['carrots']));
              setMyDollars(Number(dataJson[0]['dollars']).toFixed(3));

              setCodeComplete(Number(dataJson[0]['code_complete']));
              console.log('code_complete = ', Number(dataJson[0]['code_complete']));

              // ЕСЛИ НЕТ ПОДПИСКИ НА ОБЯЗ КАНАЛЫ
              if (Number(dataJson[0]['check_sub']) === 0) {
                setStopBot(true);
              }

              seconsFinishTurnir = Number(dataJson[0]['time_finish']);
              console.log('seconsFinishTurnir = ', seconsFinishTurnir);

              onUpdateTimeFinish(); // обновляем время до конца турнира

          },
          error: function(jqXHR, textStatus, errorThrown) {
              console.log(textStatus, errorThrown);
          }
      });
  }

  function setTurnir(hash) {
    if (hashUser === undefined || hashUser === '' || chatIDUser === undefined || chatIDUser === '') return;

    console.log('SET TURNIR()');
    console.log('СТАВКА = ', stavkaValue);

    $.ajax({
      url: 'https://test60.ru/bunny_fps/game/php/setTurnir.php',
      type: 'post',
      data: {
        hash: hash,
        stavka: stavkaValue
      },
      success: function (response) {

        setShowPreloader(false); //прелоадер

        if (response !== "stop") {

          //ПОБЕДА
          if (Number(response) === 1) {

            setSopernikScore(randomInteger(Math.round(myScore/1.2), myScore - 1) - 1);

            setMyCarrots(myCarrots + stavkaValue); // прибавляем морковки
            setMyDollars(Number(Number(myDollars) + 0.001).toFixed(3)); // прибавляем доллары
            setEnergy(energy - 1); // отнимаем энергию

            setVictoryTurnir(true); // победа в турнире
          }

          //Проигрыш
          if (Number(response) === 0) {

            if (stavkaValue > 1) setMyCarrots(myCarrots - stavkaValue); // отнимаем морковки
            setSopernikScore(randomInteger(myScore + 1, Math.round(myScore*1.2)) + 1);
            setEnergy(energy - 1); // отнимаем энергию

            setVictoryTurnir(false); // проигрыш в турнире
          }

          // Отображаем результат турнира
          setResultTurnir(true);

        } else {

          setStopTurnir(true); // турнир остановлен

        }

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });
  }

  function getAvaSopernik(hash) {
    if (hashUser === undefined || hashUser === '' || chatIDUser === undefined || chatIDUser === '') return;
    $.ajax({
      url: 'https://test60.ru/bunny_fps/game/php/getAvatarOnline.php',
      type: 'post',
      data: {
        hash: hash
      },
      success: function (response) {

        console.log('response = ', response);

        setShowPreloader(false);

        // если турнир остановлен
        if (response === "stop") {

          setStopTurnir(true); // остановлен

        } else{

          // если турнир запущен
          if (response !== "0") {

            setSearchSopernik(true); // поиск соперника
            setAvatarSpoernik(response); // аватарка соперника
            setNoEnergy(false); // энергия есть
            setStopTurnir(false); // запущен

          } else {

            // если нет энергии
            setEnergy(0);
            setNoEnergy(true); // энергии нет
            setSearchSopernik(false);
          }

        }

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });
  }

  useEffect(() => {

    if (myScore > myRecord && (hashUser !== undefined || hashUser !== '')) {

      setMyRecord(myScore);

      //Записываем рекорд в базу данных
      if (myScore === 10 | myScore === 20 | myScore === 30 | myScore === 40 | myScore === 45 | myScore === 50
      | myScore === 55 | myScore === 60 | myScore === 65 | myScore === 70 | myScore === 75 | myScore === 80
      | myScore === 85 | myScore === 90 | myScore === 95) saveMyRecord(myScore, hashUser, authTG);
      if (myScore >= 100) saveMyRecord(myScore, hashUser, authTG);
    }
  }, [myScore]);

  useMemo(
      () => onAutorization(),
      []
  );


  const handleChange = (event, newValue) => {

    setTabsValue(newValue);

    if (newValue === 0) setTurnirMode(false); // переключаемся в обычный режим
    if (newValue === 1) setTurnirMode(true); // переключаемся на турнир

    if (newValue === 2 && TurnirMode) setHistoryTurnir(true); // показываем историю турнира
    else setHistoryTurnir(false);

    getCountOnline(); // подгружаем кол-во онлайн

    setResultTurnir(false);
    setStartGame(false);
  };



  function getCountOnline() {

    if (hashUser === undefined || hashUser === '') return;
    $.ajax({
      url: 'https://test60.ru/bunny_fps/game/php/getCountOnline.php',
      type: 'post',
      data: {
        //score: score,
      },
      success: function (response) {

        console.log('response = ', response);
        setCountOnline(Number(response));

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(textStatus, errorThrown);
      }
    });

  }

  // отображение времени до конца турнира
  function onUpdateTimeFinish() {

    console.log('seconsFinishTurnir = ', seconsFinishTurnir);
    const hours = Math.floor(seconsFinishTurnir / 60 / 60);
    const minutes = Math.floor(seconsFinishTurnir / 60) - (hours * 60);
    //const seconds = timeStartTurnir % 60;

    setTimeFinishTurnir(hours + ' ч. ' + minutes + ' м.');

  }


  const onLoadComplete = () => {
    setStartGame(true);
    console.log('complete fun');
  };

  const hideSearchSopernik = () => {
    setSearchSopernik(false);
  };

  const hideNoEnergy = () => {
    setNoEnergy(false);
    setAddBonus(false);
  };

  const addEnergy = () => {
    setEnergy(energy + 30); // прибавляем 30 энергии
    setCodeComplete(1); // код активирован
    setStopTurnir(); // снимаем турнир с паузы
  };

  // Бонус за просмотр видеорекламы
  const onVideoBonus = () => {

  };

  // Открываем окно получения энергии
  const onViewEnergyCode = () => {

    setNoEnergy(true); // энергии нет

  };

  // Начисляем бонус за просмотр видео рекламы
  const onAddVideoBonus = () => {

    setAddBonus(true);
    setEnergy(energy + 1); // прибавляем 1 энергии
    if (stopTurnir) setStopTurnir(); // снимаем турнир с паузы
    setVideoBonusCount(videoBonusCount + 1); // +1 просмотр к рекламе

  };


  const hideResultTurnir = () => {
    setResultTurnir(false);
    viewADS(false); // показываем рекламу
  };

  const hideStavkaTurnir = () => {
    setStavkaTurnir(false);
  };

  const changeModeGame = (turnirMode) => {
    if (turnirMode === 1) {
      setTabsValue(1);
      setTurnirMode(true);
    }
    setModeGame(false);

    setShowPreloader(true);

    // для админов отключачем рекламу
    //if (chatIDUser === '193523422' || chatIDUser === '437127745') canADS = false; // запрещаем загрузку рекламы

    // УБИРАЕМ ВРЕМЕННО ЯНДЕКС РЕКЛАМУ
    canADS = false; // запрещаем загрузку рекламы

    // загружаем рекламу
    viewADS(false);

    /*
    // если через 8 сек если не загружена реклама, то и не показываем больше
    setTimeout(_endTimeOut, 11000);
    function _endTimeOut () {

      if (!loadedADS) {
        canADS = false; // запрещаем загрузку рекламы
        setShowPreloader(false);
      }

    }
    */

  };

  // Функция вызова показа рекламы
  const viewADS = (video) => {
    if (!canADS) {
        setShowPreloader(false);
    } else {
        if (video) window.addYaAd(true, completeVideoAds, preloaderAds); // показываем  видеорекламу
        else window.addYaAd(false, completeAds, preloaderAds); // показываем обычную рекламу
    }
  }

  // реклама загружена
  const completeAds = () => {
    console.log('РЕКЛАМА ПОДГРУЖЕНА!');
    loadedADS = true;
    setShowPreloader(false);

    // если запускается поиск соперника
    /*
    if (stavkaTurnir) {
      onAddSearchSopernik(); // ПОИСК СОПЕРНИКА
    }
    */
  }

  // ВИДЕОРЕКЛАМА УСПЕШНО ПОКАЗАНА
  const completeVideoAds = () => {
    loadedADS = true;
    setShowPreloader(false);

    onAddVideoBonus(); // выдаем бонус
  }


  // реклама загружается - ожидаем
  const preloaderAds = () => {
    console.log('Реклама загружается');
    setShowPreloader(true);
  }


  return (

    <Container maxWidth="sm" disableGutters>

      { showGame && !TurnirMode ?
          <Avatar src="https://test60.ru/bunny_fps/menu/imgs/btnBack3.png" onClick={() => { goMenuGame(); }} className={classes.iconCup}/>
      : null }

      <Box className={classes.topPanel}>

        { !TurnirMode
            ? <div className={classes.topIcon}>
              <Typography className={classes.title}>
                Мой рекорд: {myRecord}К
              </Typography>
              <Avatar src="https://test60.ru/bunny_fps/menu/imgs/bunny3.png" />
            </div>
            : null
        }

        { !showGame && TurnirMode
            ? <div className={classes.topIcon} style={{ marginRight: '0.4rem' }}>
              <Avatar src="https://test60.ru/bunny_fps/menu/imgs/invite.png" onClick={() => { goInvite(); }}/>
            </div>
            : null
        }


        {!showGame && TurnirMode
            ? <div className={classes.resoursePanel}>

              <div className={classes.topIcon} style={{ marginLeft: widthWindow < 330 ? '0.2rem' : '0.9rem' }}>
                <Avatar src="https://test60.ru/bunny_fps/menu/imgs/energy3.png"/>
                <Typography className={classes.title}>
                  {energy}
                </Typography>
              </div>

              <div className={classes.topIcon} style={{ marginLeft: '0.6rem' }}>
                <Avatar src="https://test60.ru/bunny_fps/menu/imgs/carrot2.png"/>
                <Typography className={classes.title}>
                  {myCarrots}
                </Typography>
              </div>

              <div className={classes.topIcon} style={{ marginLeft: '0.6rem', marginRight: widthWindow < 330 ? '0.2rem' : '0.9rem' }}>
                <Avatar src="https://test60.ru/bunny_fps/menu/imgs/dollar.png"/>
                <Typography className={classes.title}>
                  {myDollars}$
                </Typography>
              </div>

            </div>
            : null
        }


        { !showGame && TurnirMode
            ? <div className={classes.topIcon} style={{ marginLeft: '0.4rem' }}>
              <Avatar src="https://test60.ru/bunny_fps/menu/imgs/bank4.png" onClick={() => { goBank(); }}/>
            </div>
            : null
        }

          { showGame && TurnirMode
              ? <div className={classes.topIcon}>
                  <Avatar src="https://test60.ru/bunny_fps/menu/imgs/bunny3.png" />
                  <Typography className={classes.title}>
                      { myScore }К
                  </Typography>
              </div>
              : null
          }

          { showGame && TurnirMode
              ? <div className={classes.topIcon} style={{ marginLeft: '1.1rem' }}>
                  <Avatar src="https://test60.ru/bunny_fps/menu/imgs/time.png" />
                  <Typography className={classes.title}>
                      { timeTurnir }
                  </Typography>
              </div>
              : null
          }

      </Box>

      <Divider variant="fullWidth" />

      { openIframe ? <Iframe show={showGame} source={srcIframe} completeLoad={onLoadComplete} width={widthWindow} height={heightWindow-60}/> : null }

      { !showGame ?
          <Box>
            <Paper className={classes.root}>
              <Tabs
                  value={ tabsValue }
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
              >
                <Tab label="Обычный" />
                <Tab label="Онлайн-турнир" />
                { TurnirMode && authTG && <Tab icon={<HistoryIcon/>}/> }
              </Tabs>
            </Paper>

            { historyTurnir && <Typography className={classes.desc}> История предыдущего турнира </Typography> }

            <Paper elevation={3}>
              {
                (TurnirMode && !historyTurnir)
                  && <TurnirTopList history={false} chat_id={chatIDUser} height={ authTG ? (heightWindow - 265) : (heightWindow - 365) }/>
              }
              { !TurnirMode && <TopList height={ authTG ? (heightWindow - 255) : (heightWindow - 235) }/> }
              { historyTurnir && <TurnirTopList history={true} chat_id={chatIDUser} height={ heightWindow - 315 }/>}
            </Paper>

            { !historyTurnir && authTG && !TurnirMode &&
            <Typography className={classes.title}>
              {"Слабо попасть в топ? :)"}
            </Typography>
            }


            { !historyTurnir && authTG && TurnirMode ?
              <div className={classes.infoPanel}>
                <Avatar src="https://test60.ru/bunny_fps/menu/imgs/user_online2.png" style={{ height: '32px', width: '32px' }} />
                <Typography className={classes.title}>
                  { countOnline.toString() }
                </Typography>

                <Avatar src="https://test60.ru/bunny_fps/menu/imgs/time_finish2.png" style={{ marginLeft: '1.1rem', height: '30px', width: '30px' }} />
                <Typography className={classes.title}>
                  { timeFinishTurnir }
                </Typography>
              </div> : null
            }


            { historyTurnir && <Typography className={classes.desc} style={{ padding: '0 30px' }}> Суммарный заработок игроков за вчерашний турнир более 45$ </Typography> }


            { authTG &&
            <Button onClick={() => {
              TurnirMode ? onStartTurnir() : onGamePlay()
            }} className={classes.btn_play} size="large" variant="contained" color="primary">
              { TurnirMode ? "НАЧАТЬ ТУРНИР" : "ИГРАТЬ" }
            </Button>
            }

            <Typography className={classes.desc}>
              { authTG ? "" : "Авторизуйся через Телеграм, чтобы играть и сохранять рекорд!" }
            </Typography>


            { /*!authTG ?
                <Button onClick={() => { onAuthTG() }} className={classes.btn_auth} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary">
                  Войти через Телеграм
                </Button>
                : null
              */
            }


          </Box>
      : null }

      { searchSopernik && !stopTurnir ? <StartTurnir open={true} goGamePlay={onGamePlayTurnir} ava1={avatarPlayer} ava2={avatarSpoernik}/> : null }
      { stopTurnir && !noEnergy ? <StopTurnir open={true} onClose={hideSearchSopernik}/> : null }
      { noEnergy ? <NoEnergy open={true} onClose={hideNoEnergy} energy={energy} countEnergy={30} onPlusEnergy={addEnergy} hash={hashUser} chat_id={chatIDUser} activated={false}/> : null }
      { addBonus ? <NoEnergy open={true} onClose={hideNoEnergy} energy={energy} countEnergy={1} onPlusEnergy={hideNoEnergy} hash={hashUser} chat_id={chatIDUser} activated={true}/> : null }

      { resultTurnir && <ResultTurnir open={true} onClose={hideResultTurnir} victory={victoryTurnir} stavka={myStavka} score1={myScore} score2={mySopernikScore} ava1={avatarPlayer} ava2={avatarSpoernik}/> }

      <Stavka open={stavkaTurnir} onClose={hideStavkaTurnir} onSearch={onSearchSopernik} codeComplete={codeComplete} onBonus={onVideoBonus} onBonus30={onViewEnergyCode} videoBonusCount={videoBonusCount} myCarrots={myCarrots} hash={hashUser} chat_id={chatIDUser}/>
      <ModeGame open={authTG && modeGame} onChange={changeModeGame}/>
      <Preloader open={showPreloader}/>

      <Bank open={showBank} dollars={Number(myDollars)} onClose={() => setShowBank(false)}/>
      <Invite open={showInvite} onClose={() => setShowInvite(false)}/>

      { stopBot ? <StopBot open={true} /> : null }

      { goTelegram ? <GoTelegram open={true} /> : null }

	</Container>

  );
}

export default MenuGame;
